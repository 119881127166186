

















































































































































































import ChainSearch from '@/components/pc/BlockChainBrowser/ChainSearch.vue'
import CopyBtn from '@/components/pc/CopyBtn.vue'
import tradeDetail from '@/mixin/tradeDetail'
import vueTypedMixins from 'vue-typed-mixins'

export default vueTypedMixins(tradeDetail).extend({
  components: {
    ChainSearch,
    CopyBtn,
  },
})
